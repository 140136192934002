import React from 'react';
import DataStepForm from '../components/DataForm';

function Data() {
  return (
    <div>
      <DataStepForm />
    </div>
  );
}

export default Data;