import React from 'react';
import AirtimeStepForm from '../components/AirtimeForm';

const Airtime = () => {
  return (
    <div>
      <AirtimeStepForm />
    </div>
  );
};

export default Airtime;
