import React from 'react';

function Notfound() {
  return (
    <div className='Error-pg' id=''>
      <h1 className='Error-code'>404</h1>
      <h4 className='Error-text'>Page not found</h4>
      <p className='Error-para'>Sorry, the page you have searched does not exist.</p>
    </div>
  );
};

export default Notfound;