import React, { useState } from 'react';

function NPSForm({ onClose }) {
  const [ExperienceRating, setExperienceRating] = useState('');
  const [ExpReason, setExpReason] = useState('');
  const [RecommendRating, setRecommendRating] = useState('');
  const [RecmdReason, setRecmdReason] = useState('');
  const [Improvement, setImprovement] = useState('');
  const [formSubmitted, setformSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //SAVE FORM DATA
    const data = {
      ExperienceRating,
      ExpReason,
      RecommendRating,
      RecmdReason,
      Improvement
    };
    //STORE ENTRY TO DB
    fetch("https://sats2data-server.onrender.com/rating", {
    // fetch('/rating', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(() => {
        setformSubmitted(true);
        setExperienceRating('');
        setExpReason('');
        setRecommendRating('');
        setRecmdReason('');
        setImprovement('');
        // setTimeout(() => {
        //   setformSubmitted(false);
        // }, 5000);
        setformSubmitted(true);
        setTimeout(() => {
          onClose(); // Call the onClose function to close the modal
        }, 2000);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className='Nps-form' id=''>
      <div className='Ellipse'>
        {formSubmitted ? (
          <div className='Contact-confirm' id=''>
            <p className='C-confirm-para'>Thank you for your Feedback!</p>
          </div>
        ) : (

          <form onSubmit={handleSubmit} className='Rating'>
            <h1>Please Rate our Service</h1>
            {/* <p>We are seeking your advice on how we can improve our service. Can you please provide us with any suggestions on how we can make it better?</p> */}
            <div className="Form-group">
              <label htmlFor="Experience">1. How would you rate your top-up experience?</label>
              <div className="Experience-rate">
                <input type="radio" id="star5" name="rating1" value="5 -Very Good" checked={ExperienceRating === '5 -Very Good'} onChange={(e) => setExperienceRating(e.target.value)} />
                <label htmlFor="star5" title="5 stars">Very Good</label>
                <input type="radio" id="star4" name="rating1" value="4 -Good" checked={ExperienceRating === '4 -Good'} onChange={(e) => setExperienceRating(e.target.value)} />
                <label htmlFor="star4" title="4 stars">Good</label>
                <input type="radio" id="star3" name="rating1" value="3 -Average" checked={ExperienceRating === '3 -Average'} onChange={(e) => setExperienceRating(e.target.value)} />
                <label htmlFor="star3" title="3 stars">Average</label>
                <input type="radio" id="star2" name="rating1" value="2 -Poor" checked={ExperienceRating === '2 -Poor'} onChange={(e) => setExperienceRating(e.target.value)} />
                <label htmlFor="star2" title="2 stars">Poor</label>
                <input type="radio" id="star1" name="rating1" value="1 -Very Poor" checked={ExperienceRating === '1 -Very Poor'} onChange={(e) => setExperienceRating(e.target.value)} required />
                <label htmlFor="star1" title="1 star">Very Poor</label>
              </div>
            </div>

            <div className="Form-group">
              <label htmlFor="Exp-reason">Please what's the reason for your score above?</label>
              <textarea className="form-control" id="reason1" rows="1" value={ExpReason} onChange={(e) => setExpReason(e.target.value)} required></textarea>
            </div>

            <div className="Form-group">
              <label htmlFor="Recommendation">2. How likely are you to recommend Sats2Data to a friend?</label>
              <div className="Recommend-rate">
                <input type="radio" id="star10" name="rating2" value="5 -Very likely" checked={RecommendRating === '5 -Very likely'} onChange={(e) => setRecommendRating(e.target.value)} required />
                <label htmlFor="star10" title="5 stars">Very likely</label>
                <input type="radio" id="star9" name="rating2" value="4 -Perhaps" checked={RecommendRating === '4 -Perhaps'} onChange={(e) => setRecommendRating(e.target.value)} />
                <label htmlFor="star9" title="4 stars">Perhaps</label>
                <input type="radio" id="star8" name="rating2" value="3 -Not sure" checked={RecommendRating === '3 -Not sure'} onChange={(e) => setRecommendRating(e.target.value)} />
                <label htmlFor="star8" title="3 stars">Not sure</label>
                <input type="radio" id="star7" name="rating2" value="2 -Perhaps not" checked={RecommendRating === '2 -Perhaps not'} onChange={(e) => setRecommendRating(e.target.value)} />
                <label htmlFor="star7" title="2 stars">Perhaps not</label>
                <input type="radio" id="star6" name="rating2" value="1 -Very unlikely" checked={RecommendRating === '1 -Very unlikely'} onChange={(e) => setRecommendRating(e.target.value)} />
                <label htmlFor="star6" title="1 star">Very unlikely</label>
              </div>
            </div>

            <div className="Form-group">
              <label htmlFor="Recmd-reason">Please what's the reason for your score above?</label>
              <textarea className="form-control" id="reason2" rows="1" value={RecmdReason} onChange={(e) => setRecmdReason(e.target.value)} required></textarea>
            </div>

            <div className="Form-group">
              <label htmlFor="Advise">3. What is one area you would like us to improve on?</label>
              <textarea className="form-control" id="Advise" rows="4" value={Improvement} onChange={(e) => setImprovement(e.target.value)} required></textarea>
            </div>

            <button type="submit" className="Rating-btn">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
}

export default NPSForm;