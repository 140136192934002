import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Error() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/error') {
      navigate('/');
    }
  }, []);

  return (

    <div className='Contact-pg' id=''>
      <div className='Ellipse'>
        <div className='Contact-confirm' id=''>
          <p className='C-confirm-para error'>Sorry, an error has occurred with this phone number/operator. Please check back later!</p>
          <Link to="/">
            <button type="button" className='Success-btn' id=''>Go Home</button>
          </Link>
        </div>
      </div>
    </div>

  );
};

export default Error;
