import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Error from "../pages/error";

const StepTwo = ({ nextStep, prevStep, progressBar }) => {
  const [amount, setAmount] = useState("");
  const [operator, setOperator] = useState();
  const [phone, setPhone] = useState();
  const [flag, setFlag] = useState();
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    //SAVE FORMDATA TO LOCAL STORAGE OR PASS AS PROPS TO NEXT STEP
    const data = JSON.parse(localStorage.getItem("formData"));
    data.amount = amount;
    data.currency = operator?.destinationCurrencyCode;
    data.operator = operator?.operatorId;
    localStorage.setItem("formData", JSON.stringify(data));
    nextStep();
  };

  useEffect(() => {
    getOperator();
  }, []);

  async function getOperator() {
    let data = localStorage.getItem("formData");
    data = JSON.parse(data);
    setPhone(data.phone);
    setFlag(data.flag);
    fetch("https://sats2data-server.onrender.com/getoperator", {
      // fetch("/getoperator", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.errorCode) {
          setError(true);
        } else if (res.data.message === "Invalid phone number") {
          setError(true);
        } else {
          setOperator(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  }

  if (error) {
    return <Error />
  }

  return (
    <div className="Airtime-purchase" id="">
      <div className="Ellipse">
        <form onSubmit={handleSubmit} className="Selection" id="" >
          <div className="Operator-details">
            <div className="Operator-dits" id="">
              <img src={operator?.logoUrls[0]} alt="Provider Logo" className="Operator-img" />
              <p className="Operator-name">{operator?.name}</p>
            </div>
            <div className="Country-dits" id="">
              <img src={flag} alt="Country Flag" className="Country-img" />
              <p className="Country-name">{operator?.destinationCurrencyCode}</p>
            </div>
          </div>

          <div className="Amount-select" id="">
            <label htmlFor="amount" className="S-amount-label" id="">Input Amount</label>

            <input type="hidden" name="currency" value={operator?.destinationCurrencyCode} />
            <input type="hidden" name="operator_id" value={operator?.operatorId} />
            <input type="hidden" name="country_iso" value={operator?.country?.isoName} />
            <input type="hidden" name="phone_number" value={phone} />

            <div>
              {operator?.localFixedAmounts.length !== 0 ? (
                <div>
                  <select value={amount} onChange={(e) => setAmount(e.target.value)} id="amount" name="amount" className="S-amount-select" required>
                    <option value="" disabled selected>---Choose an amount---</option>
                    {operator?.localFixedAmounts.map((value) => (
                      <option value={value}>{operator?.destinationCurrencySymbol}{value}</option>
                    ))}
                  </select>
                  <p>You can only input from these: {operator?.destinationCurrencySymbol}{operator?.localFixedAmounts.join(', ')}</p>
                </div>
              ) : operator?.destinationCurrencyCode === 'NGN' ? (
                <div>
                  <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} name="amount" className="S-amount-input" id="amount" min={operator?.localMinAmount} max={operator?.localMaxAmount} step={operator?.localMinAmount} placeholder="0" required />
                  <p>You can input between {operator?.destinationCurrencySymbol}{operator?.localMinAmount} to {operator?.destinationCurrencySymbol}{operator?.localMaxAmount}</p>
                </div>
              ) : operator?.localMinAmount === null ? (
                <div>
                  <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} name="amount" id="amount" min="10" step="1" className="S-amount-input" placeholder="0" required />
                  <p>You can input any amount</p>
                </div>
              ) : (
                <div>
                  <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} name="amount" className="S-amount-input" id="amount" min={operator?.localMinAmount} max={operator?.localMaxAmount} step={operator?.localMinAmount} placeholder="0" required />
                  <p>You can input between {operator?.destinationCurrencySymbol}{operator.localMinAmount} to {operator?.destinationCurrencySymbol}{operator?.localMaxAmount}</p>
                </div>
              )}
            </div>
          </div>

          <div className="Issue-highlight">
            <div className='Share-msg'>
              <h6 className=''>Top-ups take less than 5 seconds! If your top-up is delayed for 30+ mins, it is due to a downtime with your network provider. <Link to="/contact-us" target="_blank" rel="noopener noreferrer">Reach out to us HERE for support.</Link></h6>
            </div>
          </div>

          <div className="Airtstep2-BTNS" id="">
            <button type="button" onClick={prevStep} className="Airtstep2-btnback" id="">Back</button>
            <button type="submit" className="Airtstep2-btn" id="">Checkout</button>
          </div>
        </form>
      </div>
      {progressBar}
    </div>
  );
};

export default StepTwo;

