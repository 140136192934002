import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/home';
import Airtime from './pages/airtime';
import Data from './pages/data';
import Contact from './pages/contact';
import Paymentsuccessful from './pages/success';
import Error from './pages/error';
import Notfound from './pages/404';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/buy-airtime' element={<Airtime />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/buy-data' element={<Data />} />
        <Route path='/pay-success' element={<Paymentsuccessful/>} />
        <Route path='/error' element={<Error />} />
        <Route path='*' element={<Notfound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;



