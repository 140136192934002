import React from 'react';
import NPSForm from './NpsForm';

function Modal({ show, onClose }) {
  const handleClick = (event) => {
    if (event.target.id === 'Popup-overlay') {
      onClose();
    }
  };

  return (
    <div id="Popup-overlay" className={show ? 'show' : ''} onClick={handleClick}>
      <div className="Modal-content">
        <span class="close" onClick={onClose}>&times;</span>
        <NPSForm onClose={onClose} />
      </div>
    </div>
  );
}

export default Modal;


// FOR THE GIVEN MODAL PAGE //
// Function Declaration section //
/* const [showModal, setShowModal] = useState(false);
useEffect(() => {
  if (window.location.pathname === '/pay-success') {
    setTimeout(() => {
      setShowModal(true);
    }, 2000);
  }
}, []); */

// Return Section //
/* <Modal show={showModal} onClose={() => setShowModal(false)} /> */
