import React, { useState } from 'react';
import ProgressBar from './ProgressBar';
import StepOne from './AirtimeStepOne';
import StepTwo from './AirtimeStepTwo';
import StepThree from './AirtimeStepThree';

const steps = ['Step 1', 'Step 2', 'Step 3'];

const AirtimeStepForm = () => {
  const [step, setStep] = useState(1);
  // const [completedSteps, setCompletedSteps] = useState([]);

  const nextStep = (data) => {
    setStep(step + 1);
  };

  const prevStep = () => {
    // Remove the last completed step when the user goes back
    // const updatedCompletedSteps = completedSteps.slice(0, -1);
    // setCompletedSteps(updatedCompletedSteps);
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <>
          <ProgressBar steps={steps} currentStep={1} completedSteps={[]} />
          <StepOne nextStep={nextStep} />
        </>
      );
    case 2:
      return (
        <>
          <ProgressBar steps={steps} currentStep={2} completedSteps={[0]} />
          <StepTwo nextStep={nextStep} prevStep={prevStep} />
        </>
      );
    case 3:
      return (
        <>
          <ProgressBar steps={steps} currentStep={3} completedSteps={[0,1]} />
          <StepThree prevStep={prevStep} />
        </>
      );
    default:
      return null;
  }
};

export default AirtimeStepForm;

