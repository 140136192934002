import React, { useState } from "react";
import logo from './logo.png';
import { NavLink as Link } from "react-router-dom";
import { FiAlignJustify } from "react-icons/fi";
import { CgClose } from "react-icons/cg";

function Navbar() {
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function handleClickMenuItem(e) {
    // Check if the clicked element is the logo and return early if it is
    if (e.target.closest('.logo')) {
      return;
    }
    // Remove the active class from the previously active item
    if (activeItem && activeItem !== e.target) {
      activeItem.classList.remove('active');
    }
    // Add the active class to the clicked item
    e.target.classList.add('active');
    setActiveItem(e.target);
    // Close the mobile menu when a menu item is clicked
    setIsMobileMenuOpen(false);
  }

  function handleMobileMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }  


  return (
    <div className="App-header">
      <div className="Nav-logo">
        <a href="/" className="logo">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
      </div>
      <button className="toggle-collapse" onClick={handleMobileMenu}>
        {isMobileMenuOpen ? <CgClose /> : <FiAlignJustify />}
      </button>

      <nav className={isMobileMenuOpen ? "Menu active" : "Menu"}>
        <div className="Nav-items">
          <ul>
            <li><Link to="/" onClick={handleClickMenuItem}>Home</Link></li>
            <li><Link to="/buy-airtime" onClick={handleClickMenuItem}>Airtime topup</Link></li>
            <li><Link to="/buy-data" onClick={handleClickMenuItem}>Data topup</Link></li>
            <li><Link to="/contact-us" onClick={handleClickMenuItem}>Contact</Link></li>
          </ul>
        </div>
      </nav>

    </div>
  );
}

export default Navbar;
