import React, { useState } from 'react';

function Contact() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setformSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //SAVE FORM DATA
    const data = {
      email,
      message
    };
    //STORE ENTRY TO DB
    fetch('https://sats2data-server.onrender.com/contact', {
    // fetch('/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(() => {
        setformSubmitted(true);
        setEmail('');
        setMessage('');
        setTimeout(() => {
          setformSubmitted(false);
        }, 5000);
      })
      .catch((error) => console.error(error));
  };


  return (
    <div className='Contact-pg' id=''>
      <div className='Ellipse'>
        {formSubmitted ? (
          <div className='Contact-confirm' id=''>
            <p className='C-confirm-para'>Thank you for your Message, we would get back to you as soon as possible!</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className='Contact' id=''>
            <h1>Get In Touch</h1>
            <p>IF YOU ENCOUNTERED ANY PROBLEM WITH YOUR TOP-UP, PLEASE LET US KNOW BY FILLING THIS FORM Or if you have any advice on how we can improve our service, we are always eager for feedback!</p>
            <div className='Contact-email' id=''>
              <label htmlFor='email' className='C-mail' id=''>Email</label>
              <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='C-email-input' placeholder='Your email address' required></input>
            </div>
            <div className='Contact-message' id=''>
              <label htmlFor='message' className='C-msg'>Message</label>
              <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} className='C-msg-txtarea' placeholder="Your message" rows="7" required> </textarea>
            </div>
            <button type="submit" className='Contact-btn' id=''>Send Message</button>
          </form>
        )}
      </div >
    </div >
  );
};

export default Contact;
