import React, { useState, useEffect } from "react";
import { RxCopy } from "react-icons/rx";
import { HiInformationCircle } from "react-icons/hi";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { QRCodeSVG } from "qrcode.react";
import logo from "./logo.png";
import Paymentsuccessful from "../pages/success";
import Error from "../pages/error";

const StepThree = ({ prevStep, progressBar }) => {
  const [invoice, setInvoice] = useState();
  const [invoiceSats, setinvoiceSats] = useState();
  const [copyMessage, setCopyMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState("pending")
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    createInvoice();
  }, []);

  let timer = null

  async function createInvoice() {
    let data = localStorage.getItem("formData");
    data = JSON.parse(data);
    setAmount(data.amount);
    setCurrency(data.currency);
    try {
      const response = await fetch("https://sats2data-server.onrender.com/createinvoice", {
      // const response = await fetch("/createinvoice", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      });
      const { invoice, invoiceSats } = await response.json();
      timer = setInterval(() => {
        checkInvoiceStatus(invoice)
      }, 20000)
      setInvoice(invoice);
      setinvoiceSats(invoiceSats);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  const handleCopy = async () => {
    try {
      const lnurl = document.getElementById("Lnurl");
      lnurl.style.display = "block";
      lnurl.select();

      const successful = document.execCommand("copy");
      lnurl.style.display = "none";

      if (successful) {
        setCopyMessage("lnurl copied!");
      } else {
        setCopyMessage("Failed to copy lnurl. Please try again.");
      }
    } catch (error) {
      console.error("Failed to copy: ", error);
      setCopyMessage("Failed to copy lnurl. Please try again.");
    }
    setTimeout(() => setCopyMessage(""), 2000);
  };


  function checkInvoiceStatus(invoice) {
    if (invoiceStatus !== "paid" || invoiceStatus !== "expired") {
      fetch("https://sats2data-server.onrender.com/invoicestatus/" + invoice?.payment_hash, {
      // fetch("/invoicestatus/" + invoice?.payment_hash, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.data.status === "expired") {
            clearInterval(timer)
            //Set error state to true
            setError(true);
          } else if (data.data.status === "paid") {
            clearInterval(timer)
            //Set success state to true
            setSuccess(true);
          }
          setInvoiceStatus(invoiceStatus); //NEW ADD//
        })
        .catch((err) => {
          console.log(err.message);
          setError(true);
        });
    }
  }

  if (success) {
    return <Paymentsuccessful />
  }

  if (error) {
    return <Error />
  }

  return (
    <div className="Airtime-purchase" id="">
      <div className="Ellipse">
        <form className="Selection" id="Airtstep3-form">

          <div className="Invoice-details">
            <p className="Invoice-text">
              You are paying
              <span className="Invoice-sats"> {invoiceSats} sats</span> for
              <span className="Invoice-amount">
                {" "}
                {currency} {amount}
              </span>{" "}
              data
            </p>
          </div>

          <div className="Lnurl-copy">
            <textarea className="Lnurl-text" id="Lnurl" readOnly value={invoice?.payment_request}></textarea>
            <span className="Lnurl-msg">{copyMessage}</span>
            <button type="button" onClick={handleCopy} className="Lnurl-btn">
              Copy invoice lnurl <RxCopy className="Lnurl-icon" />
            </button>
            <p className="Warning-text">ON MOBILE: Please do not refresh or exit the browser entirely, minimise and endeavour to return to check the status of your top-up.</p>
          </div>

          <div className="QRcode">
            <p className="QRcode-text">Scan QR code</p>
            <div className="QRcode-barcode">
              <QRCodeSVG
                value={invoice?.payment_request}
                size={280}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
                imageSettings={{
                  src: logo,
                  height: 15,
                  width: 70,
                  excavate: true,
                }}
              />
            </div>
            <a
              href={`lightning:${invoice?.payment_request}`}
              className="Open-wallet"
            >
              Open in wallet
            </a>
            <p className="Openw-info">
              <HiInformationCircle className="" /> 'Open in wallet' might not
              work on desktop
            </p>
            <a
              href="https://chrome.google.com/webstore/detail/alby-bitcoin-lightning-wa/iokeahhehimjnekafflcihljlcjccdbe"
              target="_blank"
              rel="noopener noreferrer"
              className="Alby-wallet"
            >
              <p>
                <BsArrowRightCircleFill className="Alby-text" /> Click here to
                get Alby wallet for Desktop
              </p>
            </a>
          </div>

          {/* <div className="Airtstep3-BTNS" id="">
            <button
              type="button"
              onClick={prevStep}
              className="Airtstep3-btnback"
              id=""
            >
              Back
            </button>
            <button type="submit" className="Airtstep3-btn" id="topupButton">
              Confirm payment
            </button>
          </div> */}
        </form>
      </div>
      {progressBar}
    </div>
  );
};

export default StepThree;

