import React from "react";
import { SiBitcoin } from "react-icons/si";
import { Link } from "react-router-dom";
// import insta from "../instagram.png";
import twitter from "../twitter.png"

function Home() {
  return (
    <div className="Home-details">
      <div className="Intro" id="">
        <h1>
          <span className="Intro-p1" id="">
            Buy Airtime and Data bundles
            <br /> using
          </span>
          <span className="Intro-p2" id="">
            {" "}
            Bitc
          </span>
          <span className="Intro-p3" id="">
            <SiBitcoin className="btc-icon" />
          </span>
          <span className="Intro-p2" id="">
            in.
          </span>
        </h1>
      </div>
      <div className="Para" id="">
        <p className="Para-text" id="">
          Choose your country, enter your mobile number and top up your
          <br />
          mobile phones using Bitcoin. Stress-free!
        </p>
      </div>
      <div className="Home-btns" id="">
        <Link to="/buy-airtime">
          <button className="Airtime-btn" id="">Airtime topup</button>
        </Link>

        <Link to="/buy-data">
          <button className="Data-btn" id="">Data bundle</button>
        </Link>
      </div>
      <div className="Review">
        <h1 className="Review-head">From Our Users</h1>
        <div className="Review-box">
          <h4 className="Review-text">
            This morning my sister struggled to buy call credits from her bank.
            Alot of banks here have been congested because people have been
            trying to get access to their money. A few minutes ago, I decided to
            use{" "}
            <span>
              <b>sats2data</b>
            </span>{" "}
            to pay for my call with <span>#Bitcoin</span> and it worked like a
            charm!
          </h4>
          <div className="Review-user" id="">
            <img src={twitter} className="R-user-icon" alt="" id="" />
            <h4 className="R-user-nm">Mary Imasuen</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
