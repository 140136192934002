import React from "react";
import { Link } from "react-router-dom";
import twitter from '../twitter.png';
import discord from '../discord.png';

function Footer() {
  return (
    <footer>
      <div className="App-footer" id="">
        <div className="Footer-info" id="">
          <p>To learn more about
            <span className="Bitcoin"> Bitcoin</span> please visit <span className="AB"><Link to="https://bitcoiners.africa/" target="_blank"
              rel="noopener noreferrer"> African Bitcoiners</Link></span>
              <br/>or join our community discord <span className="Discord"><Link to="https://discord.gg/wcsfGRs3mt" target="_blank"
              rel="noopener noreferrer"> here<img src={discord} className="Fsocials-icon" alt="" id=''/></Link></span>
          </p>
        </div>

        <div className="Footer-socials" id="">
          <p>Follow us on
            <Link to="https://twitter.com/Sats2data_" target="_blank"
              rel="noopener noreferrer">
              <span>
                <img src={twitter} className="Fsocials-icon" alt="" id='' />
              </span> @sats2data_
            </Link>
          </p>
        </div>
        
        {/* <p className="Copyright">
            Copyright &copy; Sats2data 2023
          </p> */}
      </div>
    </footer>

  );
}

export default Footer;