/* import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdRadioButtonUnchecked } from 'react-icons/md';

const ProgressBar = ({ currentStep }) => {
  const steps = ['Choose country', 'Amount', 'Checkout'];

  return (
    <div className='Progress-bar' id=''>
      {steps.map((step, index) => (
        <div key={step} className="progress-step">
          {index < currentStep ? (
            <FaCheckCircle className="complete" />
          ) : (
            <MdRadioButtonUnchecked className="incomplete" />
          )}
          <p>{step}</p>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar; */


/* WORKING WELL import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdRadioButtonUnchecked } from 'react-icons/md';

const ProgressBar = ({ currentStep, completedSteps }) => {
  const steps = ['Choose country', 'Amount', 'Checkout'];

  return (
    <div className='Progress-bar' id=''>
      {steps.map((step, index) => (
        <div key={step} className="Progress-step">
          <p>{step}</p>
          {completedSteps.includes(index) ? (
            <FaCheckCircle className="Complete" />
          ) : (
            <MdRadioButtonUnchecked className="Incomplete" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar; */



import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { MdRadioButtonUnchecked } from 'react-icons/md';

const ProgressBar = ({ currentStep, completedSteps, setCompletedSteps}) => {
  const steps = ['Choose country', 'Amount', 'Checkout'];

  const handleStepClick = (step) => {
    if (completedSteps.includes(step)) {
      // Remove the step from completedSteps if the user goes back to it
      const updatedCompletedSteps = completedSteps.filter((completedStep) => completedStep < step);
      setCompletedSteps(updatedCompletedSteps);
    }
  };

  return (
    <div className='Progress-bar' id=''>
      {steps.map((step, index) => (
        <div key={step} className="Progress-step" onClick={() => handleStepClick(index)}>
          <p>{step}</p>
          {completedSteps.includes(index) ? (
            <FaCheckCircle className="Complete" />
          ) : (
            <MdRadioButtonUnchecked className="Incomplete" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;

