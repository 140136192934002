import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import paid from '../paid.gif';
import { FaTwitter, FaFacebookSquare, FaWhatsappSquare, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import Modal from '../components/Modal';

function Paymentsuccessful() {

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 2000);
    if (window.location.pathname === "/pay-success") {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <div className='Pay-confirm' id=''>
        <div className='Ellipse'>
          <div className='Pay-status' id=''>
            <div className='Gif' id=''>
              <img src={paid} alt='Paid gif' className='Paid-gif' id='' />
            </div>
            <div className='Share-dits' id=''>
              <div className='Share-msg'>
              <h6 className=''>Top-ups take less than 5 seconds! If your top-up is delayed for 30+ mins, it is due to a downtime with your network provider. <Link to="/contact-us" target="_blank" rel="noopener noreferrer">Reach out to us HERE for support.</Link></h6>
              </div>
              <div className='Share-msg'>
                <h1 className=''>Tell someone about Sats2data🙂</h1>
              </div>
              <div className='Share-icons'>
                <a href="https://twitter.com/share?url=https://sats2data.africa/&text=I just topped up my phone using Sats2data africa @Sats2data_ and paid with bitcoin, so easy! Click on the link to try it out:&hastags=#Sats2data" target="_blank" rel="noopener noreferrer">
                  <FaTwitter className='S-icon-tw' />
                </a>
                <a href="http://www.facebook.com/share.php?u=https://sats2data.africa/" target="_blank" rel="noopener noreferrer">
                  <FaFacebookSquare className='S-icon-fb' />
                </a>
                <a href="https://api.whatsapp.com/send?text= Did you know you can buy airtime and data bundles using Bitcoin and under 60seconds? Yes you can with Sats2data africa! Check it out now: https://sats2data.africa/" target="_blank" rel="noopener noreferrer">
                  <FaWhatsappSquare className='S-icon-wa' />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=[https://sats2data.africa/]&title=[Looking for an easy way to buy airtime and data bundles with #Bitcoin? Check out Sats2data africa! With @Sats2data_ you can top up your phone in 60 seconds!]" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className='S-icon-in' />
                </a>
              </div>
              <div className='Share-pitch'>
                <p className=''>Practice “spend and replace”. People often regret spending but not replacing their Bitcoin years later because of how much the value increases.  Don't be that person. Every time you spend some Bitcoin, buy some more to replace it. That way you are growing the Bitcoin ecosystem and you are not missing out on the future growth in the value of Bitcoin. </p>
              </div>
            </div>
            <Link to="/">
              <button type="button" className='Success-btn' id=''>Go Home</button>
            </Link>
          </div>
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Paymentsuccessful;
