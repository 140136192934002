import React, { useState, useEffect } from 'react';

const StepOne = ({ nextStep, progressBar }) => {
  const [countryNames, setCountryNames] = useState([]);
  const [country, setCountry] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  useEffect(() => {
    fetch('https://sats2data-server.onrender.com/countries')
    // fetch("/countries")
      .then(res => res.json())
      .then(data => {
        setCountryNames(data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    //SAVE FORMDATA TO LOCAL STORAGE OR PASS AS PROPS TO NEXT STEP
    const selectedCountry = countryNames.find((countryName) => countryName.isoName === country)
    const data = {
      country,
      phone: mobileNumber,
      flag: selectedCountry.flag,
      txType: "Airtime"
    };
    localStorage.setItem('formData', JSON.stringify(data));
    nextStep();
  };

  return (
    <div className='Airtime-purchase' id=''>
      <div className='Ellipse'>
        <form onSubmit={handleSubmit} className='Selection' id=''>
          <h1>Airtime</h1>
          <div className='Country-select' id=''>
            <label htmlFor='country' className='S-country' id=''>Country</label>
            <select value={country} onChange={(e) => setCountry(e.target.value)} className='S-country-selct' required>
              <option value=''>Select a country</option>
              {countryNames.map((country, index) => (
                <option key={index} value={country.isoName}>{country.name}</option>
              ))}
            </select>

          </div>
          <div className='Phone-select' id=''>
            <label htmlFor='phone_number' className='S-number'>Mobile Number</label>
            <input type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className='S-number-input' placeholder="Input with country code '2781122334...'" required />
          </div>
          <button type="submit" className='Airtstep1-btn' id=''>Proceed</button>
        </form>
      </div>
      {progressBar}
    </div>
  );
};

export default StepOne;
