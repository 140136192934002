import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Error from "../pages/error";

const StepTwo = ({ nextStep, prevStep, progressBar }) => {
  const [amount, setAmount] = useState("");
  const [operatorData, setOperatorData] = useState();
  const [phone, setPhone] = useState();
  const [flag, setFlag] = useState();
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    //SAVE FORMDATA TO LOCAL STORAGE OR PASS AS PROPS TO NEXT STEP
    const data = JSON.parse(localStorage.getItem("formData"));
    data.amount = amount;
    data.currency = operatorData?.destinationCurrencyCode;
    data.operator = operatorData?.operatorId;
    localStorage.setItem("formData", JSON.stringify(data));
    nextStep();
  };

  useEffect(() => {
    getDataOperator();
  }, []);

  async function getDataOperator() {
    let data = localStorage.getItem("formData");
    data = JSON.parse(data);
    setPhone(data.phone);
    setFlag(data.flag);
    fetch("https://sats2data-server.onrender.com/getdataoperator", {
      // fetch("/getdataoperator", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          setError(true);
        } else {
          setOperatorData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  }

  if (error) {
    return <Error />
  }

  return (
    <div className="Airtime-purchase" id="">
      <div className="Ellipse">
        <form onSubmit={handleSubmit} className="Selection" id="" >
          <div className="Operator-details">
            <div className="Operator-dits" id="">
              <img src={operatorData?.logoUrls[0]} alt="Provider Logo" className="Operator-img" />
              <p className="Operator-name">{operatorData?.name}</p>
            </div>
            <div className="Country-dits" id="">
              <img src={flag} alt="Country Flag" className="Country-img" />
              <p className="Country-name">{operatorData?.destinationCurrencyCode}</p>
            </div>
          </div>

          <div className="Amount-select" id="">
            <label htmlFor="amount" className="S-amount-label" id="">Data Bundle</label>

            <input type="hidden" name="currency" value={operatorData?.destinationCurrencyCode} />
            <input type="hidden" name="operator_id" value={operatorData?.operatorId} />
            <input type="hidden" name="country_iso" value={operatorData?.country?.isoName} />
            <input type="hidden" name="phone_number" value={phone} />

            <div>
              {Object.keys(operatorData?.localFixedAmountsDescriptions || {}).length !== 0 ? (
                <div>
                  <select value={amount} onChange={(e) => setAmount(e.target.value)} id="amount" name="amount" className="S-amount-select" required>
                    <option value="" disabled selected>---Choose a Package---</option>
                    {Object.keys(operatorData.localFixedAmountsDescriptions).map((key) => (
                      <option value={key}>{operatorData?.destinationCurrencySymbol}{key} - {operatorData.localFixedAmountsDescriptions[key]}</option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>
                  <select value={amount} onChange={(e) => setAmount(e.target.value)} id="amount" name="amount" className="S-amount-select" required>
                    <option value="" disabled selected>---Choose a Package---</option>
                    {Object.keys(operatorData?.fixedAmountsDescriptions || {}).map((key) => (
                      <option value={key}>{operatorData?.destinationCurrencySymbol}{key} - {operatorData?.fixedAmountsDescriptions[key]}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="Issue-highlight">
            <div className='Share-msg'>
              <h6 className=''>Top-ups take less than 5 seconds! If your top-up is delayed for 30+ mins, it is due to a downtime with your network provider. <Link to="/contact-us" target="_blank" rel="noopener noreferrer">Reach out to us HERE for support.</Link></h6>
            </div>
          </div>

          <div className="Airtstep2-BTNS" id="">
            <button type="button" onClick={prevStep} className="Airtstep2-btnback" id="">Back</button>
            <button type="submit" className="Airtstep2-btn" id="">Checkout</button>
          </div>
        </form>
      </div>
      {progressBar}
    </div>
  );
};

export default StepTwo;

